import { type Components, SvgIcon, type Theme } from '@mui/material'
import { defaultTypography } from './typography'
import { lightShadows } from './shadows'
import { BORDER_RADIUS, HEIGHTS, FONTS, WIDTHS } from './constants'

export const componentOverrides: Components = {
    MuiCssBaseline: {
        styleOverrides: `
                * {
                    box-sizing: border-box;
                }
                html {
                    MozOsxFontSmoothing: grayscale;
                    WebkitFontSmoothing: antialiased;
                    height: 100%;
                    width: 100%;
                }
                body {
                    height: 100%;
                }
                #root {
                    height: 100%;
                }
                #nprogress .bar {
                    zIndex: 2000 !important;
                }
               
                @font-face {
                    font-family: ${FONTS.GT_SUPER_DISPLAY.fontFamily};
                    src: local('${FONTS.GT_SUPER_DISPLAY.fontFamily}'), local('GTSuperDisplay'), url(${FONTS.GT_SUPER_DISPLAY.url}) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: ${FONTS.GT_AMERICA_RG.fontFamily};
                    src: local('${FONTS.GT_AMERICA_RG.fontFamily}'), local('GTAmericaRg'), url(${FONTS.GT_AMERICA_RG.url}) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: ${FONTS.GT_AMERICA_MD.fontFamily};
                    src: local('${FONTS.GT_AMERICA_MD.fontFamily}'), local('GTAmericaMd'), url(${FONTS.GT_AMERICA_MD.url}) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `
    },
    MuiGrid: {
        defaultProps: {
            spacing: {
                xs: 2,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4
            },
            columns: 12
        }
    },
    MuiAlert: {
        defaultProps: {
            components: {
                CloseIcon: () => <i className={'fa-regular fa-xmark'} />
            },
            iconMapping: {
                success: (
                    <span key={'core-alert-success-icon'}>
                        <i className={'fa-regular fa-hands-clapping'} />
                    </span>
                ),
                error: (
                    <span key={'core-alert-error-icon'}>
                        <i className={'fa-regular fa-circle-exclamation'} />
                    </span>
                ),
                warning: (
                    <span key={'core-alert-warning-icon'}>
                        <i className={'fa-regular fa-triangle-exclamation'} />
                    </span>
                ),
                info: (
                    <span key={'core-alert-info-icon'}>
                        <i className={'fa-regular fa-circle-info'} />
                    </span>
                )
            }
        }
    },
    MuiAvatar: {
        defaultProps: {
            sx: {
                backgroundColor: (props) => props.palette.accent.main,
                color: (props) => props.palette.accent.contrastText
            }
        },
        styleOverrides: {
            fallback: {
                height: '75%',
                width: '75%'
            }
        }
    },
    MuiButton: {
        defaultProps: {
            onKeyDown: (event) => {
                //this is in place to suppress tab closing dialogs and popovers we want to navigate through once opened
                if (event.key === 'Tab') {
                    event.stopPropagation()
                }
            },
            disableElevation: true,
            disableFocusRipple: true
        },
        styleOverrides: {
            root: ({ ownerState }) => ({
                textTransform: 'none',
                borderWidth: '2px !important',
                borderRadius: BORDER_RADIUS.BUTTON,
                // min-width as 44px for WCAG 2.1 compliance
                minWidth: WIDTHS.MIN_BUTTON_WIDTH,

                // minimal height as 44px for WCAG 2.1 compliance
                // https://www.w3.org/WAI/WCAG21/Understanding/target-size
                // https://www.w3.org/WAI/WCAG21/Techniques/css/C42.html
                minHeight: '32px',
                padding: '4px 12px',
                // hacky way to make clickable area 44px high
                // applied to small and medium buttons because they have the same style
                '&.MuiButton-sizeSmall:before, &.MuiButton-sizeMedium:before': {
                    position: 'absolute',
                    content: '""',
                    top: '-6px',
                    bottom: '-6px',
                    right: '0',
                    left: '0'
                },

                ...((ownerState.endIcon || ownerState.startIcon) && {
                    padding: '4px 20px'
                }),
                '&.MuiButton-sizeLarge': {
                    minHeight: HEIGHTS.MEDIUM_COMPONENT_HEIGHT,
                    minWidth: WIDTHS.MIN_BUTTON_WIDTH,
                    padding: '10px 24px',
                    ...((ownerState.endIcon || ownerState.startIcon) && {
                        px: '10px 32px'
                    })
                },
                boxShadow: lightShadows[8]
            })
        },
        variants: [
            {
                props: { disableElevation: true },
                style: {
                    boxShadow: lightShadows[0]
                }
            },
            {
                props: {},
                style: ({ theme }: { theme: Theme }) => ({
                    ':focus-visible': {
                        border: `2px solid ${theme.palette.action.focus}`
                    }
                })
            }
        ]
    },
    MuiButtonBase: {
        defaultProps: {
            onKeyDown: (event) => {
                //this is in place to suppress tab closing dialogs and popovers we want to navigate through once opened
                if (event.key === 'Tab') {
                    event.stopPropagation()
                }
            }
        },
        styleOverrides: {
            root: {
                textTransform: 'none'
            }
        },
        variants: [
            {
                props: {},
                style: ({ theme }: { theme: Theme }) => ({
                    ':focus-visible': {
                        border: `2px solid ${theme.palette.action.focus}`
                    }
                })
            }
        ]
    },
    MuiCardHeader: {
        defaultProps: {
            titleTypographyProps: {
                variant: 'h5'
            },
            subheaderTypographyProps: {
                color: 'text.primary'
            }
        }
    },
    MuiCardActions: {
        styleOverrides: {
            root: {
                padding: '16px'
            }
        }
    },
    MuiCard: {
        defaultProps: {
            elevation: 8,
            raised: true
        }
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                '&.MuiAutocomplete-paper': {
                    '.Mui-focusVisible': {
                        backgroundColor: 'unset !important',
                        border: '2px solid blue'
                    }
                },
                '&.MuiPaper-outlined': {
                    border: 'none',
                    boxShadow: '0px 0px 0px 1px #DBDDE0 inset'
                }
            }
        }
    },
    MuiChip: {
        defaultProps: {
            deleteIcon: (
                <SvgIcon
                    fontSize={'small'}
                    sx={{ height: '20px', width: '20px' }}
                >
                    <i className={'fa-regular fa-circle-xmark'} />
                </SvgIcon>
            )
        },
        styleOverrides: {
            root: {
                '.MuiTypography-root': {
                    color: 'inherit'
                },
                borderRadius: BORDER_RADIUS.CHIP,
                height: '36px'
            }
        }
    },
    MuiSpeedDialIcon: {
        defaultProps: {
            icon: (
                <SvgIcon>
                    <i className={'fa-regular fa-plus'} />
                </SvgIcon>
            )
        }
    },
    MuiTab: {
        defaultProps: {
            tabIndex: 0, //this is set for a11y keyboard navigation compliance, do not override
            disableFocusRipple: true
        }
    },
    MuiMenuItem: {
        defaultProps: {
            tabIndex: 0 //this is set for a11y keyboard navigation compliance, do not override
        },
        styleOverrides: {
            root: {
                wordWrap: 'break-word',
                whiteSpace: 'initial',
                ':focus-visible': {
                    backgroundColor: 'unset !important'
                }
            }
        }
    },
    MuiLinearProgress: {
        defaultProps: {
            sx: {
                backgroundColor: (props) => props.palette.grey[200]
            }
        },
        styleOverrides: {
            root: {
                borderRadius: 3,
                overflow: 'hidden'
            }
        }
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 'auto',
                marginRight: '16px'
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            root: {
                overline: {
                    color: (props) => props.theme.palette.accent.main
                }
            }
        }
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                boxSizing: 'border-box',
                minHeight: HEIGHTS.LARGE_COMPONENT_HEIGHT
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                '&.MuiOutlinedInput-root': {
                    minHeight: HEIGHTS.LARGE_COMPONENT_HEIGHT
                }
            }
        }
    },
    MuiSelect: {
        defaultProps: {
            IconComponent: () => (
                <i
                    className={'fa-regular fa-angle-down'}
                    style={{
                        fontSize: '16px',
                        right: '1rem',
                        pointerEvents: 'none',
                        position: 'absolute'
                    }}
                />
            )
        },
        styleOverrides: {
            select: {
                boxSizing: 'border-box',
                minHeight: HEIGHTS.LARGE_COMPONENT_HEIGHT,
                ':focus-visible': {
                    backgroundColor: 'unset !important'
                }
            },
            icon: {
                fontSize: '24px'
            }
        }
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                variant: 'body2'
            }
        }
    },
    MuiSvgIcon: {
        defaultProps: {
            fontSize: 'medium'
        },
        variants: [
            {
                props: { fontSize: 'small' },
                style: {
                    height: '16px',
                    width: '16px'
                }
            },
            {
                props: { fontSize: 'medium' },
                style: {
                    height: '24px',
                    width: '24px'
                }
            },
            {
                props: { fontSize: 'large' },
                style: {
                    height: '32px',
                    width: '32px'
                }
            }
        ]
    },
    MuiSwitch: {
        defaultProps: {
            disableFocusRipple: true,
            color: 'default'
        },
        variants: [
            {
                props: {}, //all props variations will have the same color
                style: ({ theme }: { theme: Theme }) => ({
                    '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                            '& .MuiSwitch-thumb': {
                                color: `${theme.palette.primary.contrastText} !important`
                            },
                            '& + .MuiSwitch-track': {
                                backgroundColor: `${theme.palette.primary.light} !important`,
                                opacity: 1
                            }
                        },
                        '&.Mui-disabled .MuiSwitch-thumb': {
                            opacity: 1
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5
                        },
                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                            outline: `2px solid ${theme.palette.action.focus}`
                        }
                    },
                    '& .MuiSwitch-thumb': {
                        color: `${theme.palette.common.white} !important`
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: `${theme.palette.grey[500]} !important`,
                        opacity: 1
                    }
                })
            }
        ],
        styleOverrides: {
            root: {
                width: 40,
                height: 24,
                padding: 0,
                marginLeft: 12,
                marginRight: 12,
                '& .MuiSwitch-switchBase': {
                    padding: 0,
                    margin: 2,
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        '& .MuiSwitch-thumb': {}
                    }
                },
                '& .MuiSwitch-thumb': {
                    width: 20,
                    height: 20
                },
                '& .MuiSwitch-track': {
                    borderRadius: 17
                }
            }
        }
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                '.Mui-focusVisible': {
                    backgroundColor: 'unset !important'
                }
            }
        }
    },
    MuiAccordionSummary: {
        defaultProps: {
            expandIcon: (
                <i
                    className={'fa-regular fa-angle-down'}
                    style={{ fontSize: '16px' }}
                />
            )
        }
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                ':focus-visible': {
                    backgroundColor: 'unset !important'
                }
            }
        }
    },
    MuiAutocomplete: {
        defaultProps: {
            popupIcon: <i className={'fa-regular fa-angle-down'} />,
            clearIcon: <i className={'fa-regular fa-xmark'} />
        }
    },
    MuiTablePagination: {
        defaultProps: {
            backIconButtonProps: {
                children: <i className={'fa-regular fa-angle-left'} />
            },
            nextIconButtonProps: {
                children: <i className={'fa-regular fa-angle-right'} />
            }
        },
        styleOverrides: {
            select: {
                minHeight: 'auto !important',
                ...defaultTypography.body2
            }
        }
    },
    MuiToggleButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                borderRadius: BORDER_RADIUS.BUTTON
            }
        }
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                '.MuiToggleButtonGroup-groupedHorizontal': {
                    padding: '4px 12px'
                }
            }
        }
    }
}
